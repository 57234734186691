export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '年份',
    prop: 'applyYear'
  }, {
    label: '计划名称',
    prop: 'projectName'
  }, {
    label: '计划内容',
    prop: 'projectContent'
  }, {
    width: 150,
    label: '状态',
    prop: 'projectStatus',
    search: true,
    type: 'select',
    dicData: [
      {
        label: '待发布',
        value: 0
      }, {
        label: '发布',
        value: 1
      }
    ]
  }]
}
